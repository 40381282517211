import {
  AccountStatus,
  MedRoleEnum,
  TechRoleEnum,
  UserTypeResponse,
} from "@veris-health/user-ms/lib/v1";
import { IconName, Option } from "@veris-health/web-core";
import { RouteDictionaryValues, Routes } from "./routes-config";

export type TopBarMenuItems = {
  [k in RouteDictionaryValues]?: string[];
};

export const topBarMenuItems: TopBarMenuItems = {
  [Routes.STAFF_LOGIN]: ["Admin Login"],
};
export type TopBarTitles = {
  [k in RouteDictionaryValues]?: string;
};

export const topBarTitles: TopBarTitles = {
  [Routes.USER_DETAILS]: "User Details",
  [Routes.EDUCATIONAL_VIDEOS]: "Educational Videos",
  [Routes.SURVEYS]: "Surveys",
  [Routes.EDUCATIONAL_AREA]: "Learning Resources",
} as const;

export interface SideBarMenuItem {
  title: string;
  iconName: IconName;
  path: RouteDictionaryValues;
  access: TechRoleEnum[];
}
export interface ApiErrorResponse {
  detail?: string | string[] | { msg: string }[];
  message?: string;
}

export const sideBarMenuItems: SideBarMenuItem[] = [
  {
    title: "Dashboard",
    iconName: IconName.Dashboard,
    path: Routes.DASHBOARD,
    access: [TechRoleEnum.Hospitaladmin, TechRoleEnum.Admin, TechRoleEnum.Superadmin],
  },
  {
    title: "Actions",
    iconName: IconName.Actions,
    path: Routes.ACTIONS,
    access: [TechRoleEnum.Hospitaladmin, TechRoleEnum.Admin, TechRoleEnum.Superadmin],
  },
  {
    title: "Billing",
    iconName: IconName.Billing,
    path: Routes.BILLING,
    access: [TechRoleEnum.Hospitaladmin, TechRoleEnum.Admin, TechRoleEnum.Superadmin],
  },
  {
    title: "Billing Dashboard",
    iconName: IconName.Billing,
    path: Routes.BILLING_REPORTS,
    access: [TechRoleEnum.Billingexpert],
  },
  {
    title: "Learning Resources",
    iconName: IconName.Article,
    path: Routes.EDUCATIONAL_AREA,
    access: [TechRoleEnum.Superadmin],
  },
  {
    title: "Veris Support",
    iconName: IconName.ChatSupport,
    path: Routes.SUPPORT,
    access: [TechRoleEnum.Superadmin],
  },
  {
    title: "Surveys",
    iconName: IconName.Survey,
    path: Routes.SURVEYS,
    access: [TechRoleEnum.Superadmin],
  },
  {
    title: "Implant",
    iconName: IconName.Implant,
    path: Routes.IMPLANT,
    access: [TechRoleEnum.Implantmanufacturer, TechRoleEnum.Superadmin],
  },
  {
    title: "Exports",
    iconName: IconName.Download,
    path: Routes.EXPORTS,
    access: [TechRoleEnum.Billingexpert, TechRoleEnum.Superadmin],
  },
];

/**
 * In minutes.
 */
export const SessionWarningTimeout = 13;

export const testAccountEmail = "test.automation@loka.com";
export const testOTP = "9537";

export const numberRegexpPattern = /^[0-9\b]+$/;
export const textRegexpPattern = /^[a-zA-Z0-9-._ ]*$/;

export const formattedUserType = {
  med_staff: "Medical Staff",
  patient: "Patient",
  tech_staff: "Technical Staff",
  procedure_staff: "Procedure Staff",
  support_member: "Support Member",
};

export const formattedAccountStatus = {
  active: "Active",
  disabled: "Disabled",
  not_registered: "Not Registered",
  "N/A": "N/A",
};
export const userTypeOptions: Option[] = [
  {
    label: "All",
    value: "",
  },
  {
    label: formattedUserType[UserTypeResponse.MedStaff],
    value: UserTypeResponse.MedStaff,
  },
  {
    label: formattedUserType[UserTypeResponse.Patient],
    value: UserTypeResponse.Patient,
  },
  {
    label: formattedUserType[UserTypeResponse.TechStaff],
    value: UserTypeResponse.TechStaff,
  },
  ...(!["production", "preprod"].includes(import.meta.env.VITE_ENVIRONMENT)
    ? [
        {
          label: formattedUserType[UserTypeResponse.ProcedureStaff],
          value: UserTypeResponse.ProcedureStaff,
        },
      ]
    : []),
  {
    label: formattedUserType[UserTypeResponse.SupportMember],
    value: UserTypeResponse.SupportMember,
  },
];

export const activeOptions: Option[] = [
  {
    label: "All",
    value: "All",
  },
  {
    label: formattedAccountStatus[AccountStatus.Active],
    value: AccountStatus.Active,
  },
  {
    label: formattedAccountStatus[AccountStatus.Disabled],
    value: AccountStatus.Disabled,
  },
  {
    label: formattedAccountStatus[AccountStatus.NotRegistered],
    value: AccountStatus.NotRegistered,
  },
];

export const NOTIFICATIONS_OFFSET = 10;
export const USERS_FETCHING_LIMIT = 15;
export const SNACKBAR_AUTOHIDE_DURATION = 115000;

export const RoleNames: Record<TechRoleEnum | MedRoleEnum, string> = {
  superadmin: "Super Admin",
  admin: "Admin",
  hospitaladmin: "Hospital Admin",
  nurse: "Nurse",
  doctor: "Doctor",
  "physician-assistant": "Physician Assistant",
  "nurse-practitioner": "Nurse Practitioner",
  "medical-assistant": "Medical Assistant",
  "administrative-clerk": "Administrative Clerk",
  manager: "Manager",
  biller: "Biller",
  coder: "Coder",
  researcher: "Researcher",
  "director-of-research": "Director of Research",
  support: "Support",
  billingexpert: "Billing Expert",
  implantmanufacturer: "Implant Manufacturer",
};
