import axios from "axios";

export const validatePassword = (password: string): boolean => {
  const matchUppercase = "^(?=.*[A-Z])";
  const matchLowercase = "^(?=.*[a-z])";
  const matchSymbols = "^(?=.*[!@#$%^&*+=()\\-_])";
  const matchNumbers = "^(?=.*[0-9])";
  let fulfilledRequirements = 0;

  const passwordRegex = [matchUppercase, matchLowercase, matchSymbols, matchNumbers];

  passwordRegex.forEach((regex) => {
    if (password.match(regex)) fulfilledRequirements += 1;
  });

  return fulfilledRequirements < 3;
};

function getMsgFromDetails(details: any): string | undefined {
  if (Array.isArray(details) && details.length > 0) {
    const firstItem = details[0];
    if (typeof firstItem === "object" && "msg" in firstItem) {
      return firstItem.msg;
    }
  }
  return undefined;
}

export const extractErrorMessage = (error: unknown): string | undefined => {
  if (axios.isAxiosError(error)) {
    const { response } = error;
    if (!response) throw error;
    const { data } = response;
    if (data && data.message) return typeof data.message === "string" ? data.message : undefined;
    if (!data || !data.detail) throw error;
    return typeof data.detail === "string" ? data.detail : getMsgFromDetails(data.detail);
  }
  throw error;
};
