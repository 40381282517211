import React, { useState } from "react";
import { Typography, Box, DialogContent, Grid, useTheme } from "@mui/material";
import { IconName, VrsButton, VrsDialog, VrsIcon } from "@veris-health/web-core";
import { AxiosError } from "axios";
import { uploadImplantData } from "../api/implant";
import SnackbarUtils from "../../../utils/SnackbarUtils";
import { ApiErrorResponse } from "../../../constants";

export const ImplantDataUpload = (): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<File>();

  const theme = useTheme();

  const handleClose = () => {
    setFile(undefined);
    setOpen(false);
  };

  const onSubmit = () => {
    if (file) {
      uploadImplantData(file)
        .then(() => {
          SnackbarUtils.success("Successfully uploaded implant data");
          handleClose();
        })
        .catch((err: AxiosError<ApiErrorResponse>) => {
          SnackbarUtils.error(
            err.response?.data.message || "Something went wrong. Please try again!",
          );
        });
    }
  };

  return (
    <Box pb={2} display="flex" justifyContent="flex-start">
      <VrsButton buttonType="primary" size="small" onClick={() => setOpen(true)}>
        <Typography pl={3} variant="bodySemibold" fontWeight={400} pt={0.25}>
          Upload From CSV
        </Typography>
        <Box pr={3} pl={1} pt={0.75}>
          <VrsIcon name={IconName.Plus} size={18} stroke={theme.veris.colors.neutrals.white} />
        </Box>
      </VrsButton>
      <VrsDialog
        open={open}
        onClose={() => handleClose()}
        title="Import Implant Data from CSV"
        sx={{ "& .MuiPaper-root": { height: "auto" } }}
      >
        <DialogContent sx={{ overflowY: "unset" }}>
          <Grid item xs={12} container spacing={4} style={{ margin: "auto", width: "auto" }}>
            <Grid item xs={12} pb={1} sx={{ display: "flex", alignItems: "end" }}>
              <input
                type="file"
                id="csvFileInput"
                accept=".csv"
                onChange={(e) => {
                  const csv = e.target.files ? e.target.files[0] : null;
                  if (!csv) {
                    return;
                  }
                  setFile(csv);
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              marginTop={4}
              style={{ display: "flex", flexDirection: "row-reverse" }}
            >
              <VrsButton
                buttonType="primary"
                disabled={!file}
                variant="contained"
                color="primary"
                onClick={onSubmit}
              >
                Upload
              </VrsButton>
            </Grid>
          </Grid>
        </DialogContent>
      </VrsDialog>
    </Box>
  );
};
