import { Box, CircularProgress, Grid, Typography, useTheme } from "@mui/material";
import {
  AdminDetailUserInfoResponse,
  PatientStatusEnumAPI,
  PatientType,
  UpdatePatientDataRequest,
} from "@veris-health/user-ms/lib/v1";
import * as yup from "yup";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import { FormikProvider, useFormik } from "formik";
import { AxiosError } from "axios";
import {
  VrsButton,
  VrsConfirmationModals,
  VrsErrorMsg,
  VrsSelect,
  dateFormats,
} from "@veris-health/web-core";
import { capitalize } from "lodash";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { selectUsersStatus } from "../userSlice";
import { updatePatientData, updatePatientStatus } from "../api/patientsApi";
import { Status, VrsPatientInfo } from "../../shared/interfaces";
import { VrsFormInputField } from "../../../ui/components/VrsFormInputField";
import { useProfile } from "../../../context/profile";
import { PhoneFormInputField } from "../../LoginRegistration/PhoneFormInputField";
import SnackbarUtils from "../../../utils/SnackbarUtils";
import { VrsDateInputField } from "../../../ui/components/VrsDateField";
import { EditHubSN } from "./EditHubSN";
import { checkUserRoles } from "../../../utils/access";
import { getInternationalPhoneFormat, getParsedPhoneNumber } from "../helpers/utils";
import { utcToLocal } from "../../../utils/date";
import { ApiErrorResponse } from "../../../constants";

function UserInfoLabel({ fieldName }: { fieldName: string }): JSX.Element {
  return (
    <Box display="flex" sx={{ minWidth: "fit-content" }}>
      <Typography
        variant="body"
        color={(theme) => theme.veris.colors.neutrals["grey-3"]}
        pr={2}
        pl={1}
      >
        {fieldName}
      </Typography>
    </Box>
  );
}

function UserInfoValue({ fieldValue }: { fieldValue: string | boolean | undefined }): JSX.Element {
  return (
    <Box display="flex">
      <Typography
        variant="body"
        pr={2}
        pl={1}
        sx={{
          color: (theme) => theme.veris.colors.neutrals["grey-4"],
          padding: (theme) => theme.spacing(0.25, 1, 0, 1),
        }}
      >
        {String(fieldValue)}
      </Typography>
    </Box>
  );
}

const validationSchema = yup.object({
  name: yup.string().required("Please enter Last Name, First Name"),
  phoneNumber: yup
    .string()
    .required("Phone Number is required")
    .max(20, "Phone Number must be at most 20 characters")
    .when("country", {
      is: (value: string) => value.length >= 2,
      then: yup.string().min(8, "Phone Number must be at least 8 characters"),
      otherwise: yup.string().min(10, "Phone Number must be at least 10 characters"),
    })
    .matches(/^[+]?\d+$/, "Should contain only digits"),
  cancerType: yup.string(),
  cancerStage: yup.string(),
  ehrId: yup.string().required("EHR ID is required."),
  doB: yup
    .date()
    .required("Date of birth is required.")
    .typeError("The value must be a date")
    .max(dayjs(), "Invalid date"),
});

const UserDemographics = ({
  user,
  patientInfo,
  userLoading,
  getUserInfo,
}: {
  user: AdminDetailUserInfoResponse | undefined;
  patientInfo: VrsPatientInfo | undefined;
  userLoading: Status;
  getUserInfo: () => void;
}): JSX.Element => {
  const userStatus = useAppSelector(selectUsersStatus);
  const loggedUser = useProfile();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [userName, setUserName] = useState<string | undefined>("");
  const isPatient = user?.type === "patient" && userLoading === "idle";
  const theme = useTheme();
  const [confirmationDialog, setConfirmationDialog] = useState<boolean>(false);

  const handleDateValue = (fieldValue?: string) =>
    fieldValue === undefined || !fieldValue
      ? "N/A"
      : utcToLocal(fieldValue).format(`${dateFormats["MMM DD, YYYY"]}, ${dateFormats["hh:mm"]} A`);

  const {
    active,
    date_changed: dateChanged,
    date_created: dateCreated,
    date_registered: dateRegistered,
    email,
    hospital,
    lock_status: lockStatus,
    name: fullName,
    phone_number: phone,
    type,
    mio_hub_serial_number: hubSN,
  } = user || {};

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: fullName,
      phoneNumber: phone && (getParsedPhoneNumber(phone)?.nationalNumber || phone),
      cancerType: patientInfo?.diagnosis?.cancerType || "",
      cancerStage: patientInfo?.diagnosis?.cancerStage || "",
      ehrId: patientInfo?.ehrId || "",
      doB: patientInfo?.dob ? dayjs(patientInfo.dob) : undefined,
      country: phone && getParsedPhoneNumber(phone)?.countryCallingCode,
      patientStatus: patientInfo?.patientStatus,
    },
    validationSchema,
    onSubmit: async ({
      name,
      phoneNumber,
      country,
      cancerType,
      cancerStage,
      doB,
      ehrId,
      patientStatus,
    }) => {
      const payload: UpdatePatientDataRequest = {
        full_name: name,
        phone_number: `+${country}${phoneNumber}`,
        cancer_type: cancerType !== "N/A" && cancerType?.length ? cancerType : undefined,
        cancer_stage: cancerStage !== "N/A" && cancerStage?.length ? cancerStage : undefined,
        dob: doB ? doB.format() : undefined,
        ehr_id: ehrId,
      };
      if (patientInfo) {
        const updateData = updatePatientData(patientInfo.id, payload);
        const promises: Promise<void>[] = [updateData];

        if (patientInfo.patientStatus !== patientStatus) {
          const updateStatus = updatePatientStatus(
            patientInfo.id,
            patientStatus as unknown as PatientStatusEnumAPI,
          );
          promises.push(updateStatus);
        }
        await Promise.all(promises)
          .then(() => {
            SnackbarUtils.success("Patient details sucessfully updated.");
            setEditMode(false);
            getUserInfo();
          })
          .catch((err: AxiosError<ApiErrorResponse>) => {
            SnackbarUtils.error(err.response?.data.message ?? "Unable to edit patient details");
          });
      }
      if (name !== userName) setUserName(name);
    },
  });

  const { name, phoneNumber, cancerType, cancerStage, ehrId, doB, country, patientStatus } =
    formik.values;

  const { errors, handleSubmit, handleChange, handleBlur, touched, setFieldValue, resetForm } =
    formik;

  const userFields = [
    {
      label: "Full Name",
      name: "name",
      value: name,
      helperText: touched.name && errors.name ? errors.name : " ",
      error: !!touched.name && Boolean(errors.name),
      isValidField: name && name.length && !errors.name,
      editable: true,
    },
    {
      label: "Phone Number",
      name: "phoneNumber",
      value: phoneNumber,
      helperText: touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber : " ",
      error: Boolean(errors.phoneNumber) && !!touched.phoneNumber,
      isValidField: phoneNumber && phoneNumber.length && !errors.phoneNumber,
      editable: !active,
    },
    { name: "Type", value: type || "" },
    { name: "Hospital", value: "" },
    { name: "Email", value: email || "" },
    { name: "Registered", value: active ? "yes" : "no" },
    {
      name: "Lock status",
      value: lockStatus === "active" ? "not locked" : lockStatus || "",
    },
    {
      name: "Date Registered",
      value: handleDateValue(dateRegistered),
    },
    {
      name: "Date Created",
      value: handleDateValue(dateCreated),
    },
    {
      name: "Date Changed",
      value: handleDateValue(dateChanged),
    },
  ];

  const patientFields = [
    {
      label: "Cancer type",
      name: "cancerType",
      value: cancerType,
      helperText: touched.cancerType && errors.cancerType ? errors.cancerType : " ",
      error: !!touched.cancerType && Boolean(errors.cancerType),
      isValidField: cancerType?.length && !errors.cancerType,
      editable: true,
    },
    {
      label: "Cancer stage",
      name: "cancerStage",
      value: cancerStage,
      helperText: touched.cancerStage && errors.cancerStage ? errors.cancerStage : " ",
      error: !!touched.cancerStage && Boolean(errors.cancerStage),
      isValidField: cancerStage?.length && !errors.cancerStage,
      editable: true,
    },
    {
      label: "EHR ID",
      name: "ehrId",
      value: ehrId,
      helperText: touched.ehrId && errors.ehrId ? errors.ehrId : " ",
      error: !!touched.ehrId && Boolean(errors.ehrId),
      isValidField: ehrId?.length && !errors.ehrId,
      editable: true,
    },
    {
      label: "Date of birth",
      name: "doB",
      value: doB,
      helperText: touched.doB && errors.doB ? errors.doB : " ",
      error: !!touched.doB && Boolean(errors.doB),
      isValidField: doB && !errors.doB,
      editable: true,
    },
    {
      label: "Status",
      name: "status",
      value: patientStatus,
      helperText: touched.patientStatus && errors.patientStatus ? errors.patientStatus : " ",
      error: !!touched.patientStatus && Boolean(errors.patientStatus),
      isValidField: patientStatus?.length && !errors.patientStatus,
      editable: true,
    },
    { label: "HUB Serial Number", value: hubSN, name: "HUB Serial Number" },
  ];

  const fields =
    isPatient && userLoading === "idle" ? [...userFields, ...patientFields] : userFields;
  const isEditing = (value: boolean | undefined) => editMode && value === true;
  const getInputValue = (value: string | boolean) => (value !== "N/A" && value !== "" ? value : "");
  const getFieldValue = (value: string | Dayjs | boolean | undefined) => {
    if (dayjs.isDayjs(value)) {
      return value ? value.format(dateFormats["MM/DD/YYYY"]) : "N/A";
    }
    return value || "N/A";
  };

  useEffect(() => {
    if (user && userName !== user.name) setUserName(user.name);
  }, [user]);

  return (
    <Box
      px={1}
      py={2}
      sx={{
        backgroundColor: theme.veris.colors.neutrals.white,
        border: `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
        borderRadius: theme.spacing(1),
      }}
    >
      <VrsConfirmationModals
        isOpen={confirmationDialog}
        handleClose={() => setConfirmationDialog(false)}
        dialogHeader="Please Confirm"
        dialogContent={`Are you sure you want to change ${
          patientInfo?.name
        }'s status from ${capitalize(patientInfo?.patientStatus)} to ${capitalize(patientStatus)}?`}
        onConfirm={() => {
          formik.submitForm();
          setConfirmationDialog(false);
        }}
        cancelButtonText="Cancel"
        onCancel={() => setConfirmationDialog(false)}
        confirmButtonText="Save"
      />
      {userStatus === "idle" && (
        <FormikProvider value={formik}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (patientStatus !== patientInfo?.patientStatus) setConfirmationDialog(true);
              else handleSubmit(e);
            }}
          >
            <Box display="flex" justifyContent="space-between" p={1} mb={2}>
              <Typography variant="h3">
                {userName}
                {user?.test_user && (
                  <sub style={{ color: theme.veris.colors.amethyst.normal, marginLeft: "2px" }}>
                    Test
                  </sub>
                )}
              </Typography>
              <Grid item xs={2}>
                {isPatient &&
                  checkUserRoles(
                    ["superadmin", "admin", "hospitaladmin"],
                    loggedUser?.user_roles,
                  ) && (
                    <>
                      {editMode ? (
                        <Box display="flex" justifyContent="flex-end" gap={2}>
                          <VrsButton
                            buttonType="secondary"
                            onClick={() => {
                              setEditMode(false);
                              resetForm();
                            }}
                          >
                            Cancel
                          </VrsButton>
                          <VrsButton buttonType="primary" type="submit">
                            Save
                          </VrsButton>
                        </Box>
                      ) : (
                        <Box display="flex" justifyContent="flex-end" gap={2}>
                          <VrsButton buttonType="secondary" onClick={() => setEditMode(true)}>
                            Edit Details
                          </VrsButton>
                          {patientInfo?.type === PatientType.Mio && (
                            <EditHubSN
                              hubNumber={hubSN || ""}
                              userId={user.id}
                              getUserInfo={getUserInfo}
                            />
                          )}
                        </Box>
                      )}
                    </>
                  )}
              </Grid>
            </Box>
            <Grid container spacing={2}>
              {fields.map((field) => (
                <Grid
                  key={field.name}
                  display="flex"
                  alignItems="baseline"
                  pb={isEditing(field.editable) ? "0 !important" : 2}
                  item
                  xs={6}
                  pr={2}
                >
                  {field.name === "Hospital" && hospital && (
                    <Grid container>
                      <Grid item xs={3}>
                        <UserInfoLabel fieldName={field.name} />
                      </Grid>
                      <Grid item xs={6}>
                        {hospital.length > 0 ? (
                          <>
                            {hospital.map((item) => (
                              <UserInfoValue fieldValue={item.name} key={item.id} />
                            ))}
                          </>
                        ) : (
                          <UserInfoValue fieldValue="N/A" />
                        )}
                      </Grid>
                    </Grid>
                  )}
                  {isEditing(field.editable) ? (
                    <Grid container>
                      <Grid item xs={3} sx={{ alignSelf: "center" }}>
                        <UserInfoLabel fieldName={field.label || field.name} />
                      </Grid>
                      <Grid item xs={6}>
                        {field.name === "phoneNumber" && typeof field.value === "string" && (
                          <PhoneFormInputField
                            name={field.name}
                            value={field.value || ""}
                            onChange={handleChange}
                            helperText={field.helperText}
                            onBlur={handleBlur}
                            error={field.error || false}
                            isValidField={field.isValidField || false}
                            countryCode={country || "1"}
                            onSelected={(value) => setFieldValue("country", value.code.trim())}
                          />
                        )}
                        {field.name === "doB" && (
                          <VrsDateInputField
                            name={field.name}
                            maxDate={dayjs()}
                            label="Date of Birth"
                            value={dayjs(doB)}
                            helperText={field.helperText}
                            error={field.error || false}
                            onChange={(nameField, value) => {
                              setFieldValue(nameField, value);
                            }}
                            onBlur={handleBlur}
                          />
                        )}
                        {field.name === "status" && (
                          <VrsSelect
                            options={Object.values(PatientStatusEnumAPI).map((value) => {
                              return {
                                label: value,
                                value,
                              };
                            })}
                            onSelected={(value) => setFieldValue("patientStatus", value)}
                            value={field?.value as string}
                            innerSx={{ padding: theme.spacing(0.75, 0.75, 0.75, 0) }}
                            maxHeight="250px"
                          />
                        )}
                        {!["phoneNumber", "doB", "status"].includes(field.name) &&
                          typeof field.value === "string" && (
                            <VrsFormInputField
                              name={field.name}
                              value={(field.value && getInputValue(field.value)) || ""}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              helperText={field.helperText}
                              error={field.error || false}
                              isValidField={field.isValidField || false}
                            />
                          )}
                      </Grid>
                    </Grid>
                  ) : (
                    field.name !== "Hospital" && (
                      <Grid container>
                        <Grid item xs={3}>
                          <UserInfoLabel fieldName={field.label || field.name} />
                        </Grid>
                        <Grid item xs={6}>
                          <UserInfoValue
                            fieldValue={
                              field.name === "phoneNumber" && phoneNumber !== "" && country
                                ? getInternationalPhoneFormat(`+${country}${phoneNumber}`)
                                : getFieldValue(field.value)
                            }
                          />
                        </Grid>
                      </Grid>
                    )
                  )}
                </Grid>
              ))}
            </Grid>
          </form>
        </FormikProvider>
      )}
      {(userStatus === "loading" || userLoading === "loading") && (
        <Box height="20vh" display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {userStatus === "failed" && <VrsErrorMsg size="small" />}
    </Box>
  );
};

export default UserDemographics;
