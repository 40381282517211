import { DialogContent, Grid } from "@mui/material";
import { VrsButton, VrsDialog } from "@veris-health/web-core";
import { AxiosError } from "axios";
import { FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import { VrsFormInputField } from "../../../ui/components/VrsFormInputField";
import SnackbarUtils from "../../../utils/SnackbarUtils";
import { updateHubSN } from "../api/patientsApi";
import { ApiErrorResponse } from "../../../constants";

const validationSchema = yup.object({
  hubSN: yup.string().required("HUB Serial Number is required"),
});

interface EditHubSNProps {
  hubNumber: string;
  userId: number;
  getUserInfo: () => void;
}
export const EditHubSN = ({ userId, hubNumber, getUserInfo }: EditHubSNProps): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);

  const initialValues = {
    hubSN: hubNumber,
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: (values) => {
      updateHubSN(userId, values.hubSN)
        .then(() => {
          SnackbarUtils.success("HUB Serial Number Changed");
          getUserInfo();
        })
        .catch((e: AxiosError<ApiErrorResponse>) => {
          const errorMessage = e.response
            ? e.response.data.message
            : "Couldn't not update MIO HUB SN. Please try againg";
          SnackbarUtils.error(errorMessage);
        })
        .finally(() => {
          formik.resetForm();
          setOpen(false);
        });
    },
  });

  const { hubSN } = formik.values;

  const { errors, handleChange, touched, handleBlur, handleSubmit } = formik;

  return (
    <>
      <VrsButton buttonType="secondary" onClick={() => setOpen(true)}>
        Edit HUB SN
      </VrsButton>
      {open && (
        <VrsDialog
          open={open}
          onClose={() => {
            formik.resetForm();
            setOpen(false);
          }}
          title="Edit MIO HUB Serial Number"
          sx={{ "& .MuiPaper-root": { height: "auto" } }}
        >
          <DialogContent sx={{ overflowY: "unset" }}>
            <FormikProvider value={formik}>
              <form onSubmit={handleSubmit}>
                <VrsFormInputField
                  name="hubSN"
                  label="HUB Serial Number"
                  helperText={touched.hubSN && errors.hubSN ? errors.hubSN : " "}
                  error={!!touched.hubSN && Boolean(errors.hubSN)}
                  isValidField={hubSN?.length && !errors.hubSN}
                  value={hubSN}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <Grid
                  item
                  xs={12}
                  gap={2}
                  marginTop={4}
                  style={{ display: "flex", flexDirection: "row-reverse" }}
                >
                  <VrsButton
                    buttonType="primary"
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={hubNumber === hubSN || Boolean(errors.hubSN)}
                  >
                    Submit
                  </VrsButton>
                  <VrsButton
                    buttonType="secondary"
                    onClick={() => {
                      formik.resetForm();
                      setOpen(false);
                    }}
                  >
                    Close
                  </VrsButton>
                </Grid>
              </form>
            </FormikProvider>
          </DialogContent>
        </VrsDialog>
      )}
    </>
  );
};
