import { Box, Typography, Button, Stack, CircularProgress, Snackbar, Grid } from "@mui/material";
import {
  MedRoleEnum,
  TechRoleEnum,
  UserRoleTypeProfile,
  UserSorting,
  UserTypeResponse,
} from "@veris-health/user-ms/lib/v1";
import updateLocale from "dayjs/plugin/updateLocale";
import dayjs from "dayjs";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  VrsAlert,
  VrsSelect,
  useCountDown,
  useTargetTimestamp,
  VrsInfo,
  VrsErrorMsg,
  dateFormats,
} from "@veris-health/web-core";
import {
  AccountStatus,
  MedRoleFilterEnum,
  TechRoleFilterEnum,
} from "@veris-health/user-ms/lib/v1/api";
import {
  userTypeOptions,
  activeOptions,
  SNACKBAR_AUTOHIDE_DURATION,
  USERS_FETCHING_LIMIT,
  RoleNames,
} from "../../constants";
import { useRoles } from "../../context/roles";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { replaceRouteParam } from "../../routes-config";
import { VrsPlainSearch } from "../../ui/components/VrsPlainSearch";
import {
  clearActionErrorMessage,
  deleteUserAsync,
  disableUserAsync,
  getUsersAsync,
  resendInviteAsync,
  selectActionError,
  selectHospitals,
  selectRemainingSeconds,
  selectUsers,
  selectUsersStatus,
  unblockUserAsync,
} from "./userSlice";
import { UsersTable } from "./UsersTable";
import { VrsGroupedSelect } from "../../ui/components/VrsGroupedSelect";

const TableFilters = ({
  userTypeFilter,
  activeFilter,
  setUserTypeFilter,
  setActiveFilter,
  searchQuery,
  hospitalFilter,
  setHospitalFilter,
  onValueChange,
  clearFilters,
  sortingFilter,
  roleFilter,
  setRoleFilter,
}: {
  userTypeFilter: string;
  activeFilter: AccountStatus | string;
  setUserTypeFilter: (filter: string) => void;
  setActiveFilter: (filter: string) => void;
  searchQuery: string;
  hospitalFilter: string;
  setHospitalFilter: (filter: string) => void;
  onValueChange: (value: string, filterName: string) => void;
  sortingFilter: string;
  clearFilters: () => void;
  roleFilter: string;
  setRoleFilter: (filter: string) => void;
}) => {
  const hospitals = useAppSelector(selectHospitals);
  const medStaffRoles = useRoles(UserRoleTypeProfile.MedStaff);
  const techStaffRoles = useRoles(UserRoleTypeProfile.TechStaff);

  const filteredRoles = useMemo(() => {
    const medStaffOptions = medStaffRoles.map((value) => {
      return {
        label: value?.role_name ? RoleNames[value.role_name as MedRoleEnum] : "",
        value: `${value?.role_name}|${UserRoleTypeProfile.MedStaff}`,
      };
    });
    const techStaffOptions = techStaffRoles.map((value) => {
      return {
        label: value?.role_name ? RoleNames[value.role_name as TechRoleEnum] : "",
        value: `${value?.role_name}|${UserRoleTypeProfile.TechStaff}`,
      };
    });
    switch (userTypeFilter) {
      case "med_staff":
        return [
          {
            options: medStaffOptions,
          },
        ];
      case "tech_staff":
        return [
          {
            options: techStaffOptions,
          },
        ];
      case "":
        return [
          {
            options: medStaffOptions,
            label: "Medical Staff Roles",
          },
          {
            options: techStaffOptions,
            label: "Tech Staff Roles",
          },
        ];
      default:
        return [];
    }
  }, [userTypeFilter, medStaffRoles, techStaffRoles]);

  const hospitalOptions = [
    { label: "All", value: "" },
    { label: "None", value: "-1" },
  ].concat(
    hospitals.map((hospital) => ({
      label: hospital.name,
      value: `${hospital.id}`,
    })),
  );

  useEffect(() => {
    // Check if the value exists in the options for any of the roles
    const hasValue = filteredRoles.some((role) => hasValueInOptions(role.options, `${roleFilter}`));
    if (!hasValue) {
      setRoleFilter("");
    }
  }, [userTypeFilter]);

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Box>
          <Typography variant="body1">User Type</Typography>
          <VrsSelect
            outerSx={{ width: "150px" }}
            value={userTypeFilter}
            options={userTypeOptions}
            onSelected={setUserTypeFilter}
          />
        </Box>
        <Box>
          <Typography variant="body1">Account Status</Typography>
          <VrsSelect
            outerSx={{ width: "150px" }}
            value={activeFilter}
            options={activeOptions}
            onSelected={setActiveFilter}
          />
        </Box>
        <Box>
          <Grid container>
            <Typography variant="body1" sx={{ display: "flex" }}>
              Search
            </Typography>
            <VrsInfo
              description="Search by name, email, phone number or hub serial number"
              size={15}
            />
          </Grid>
          <VrsPlainSearch
            onValueChange={useCallback(
              (value: string) => onValueChange(value, "search"),
              [userTypeFilter, activeFilter, hospitalFilter, sortingFilter, roleFilter],
            )}
            value={searchQuery}
          />
        </Box>
      </Stack>
      <Stack ml="auto" direction="row" spacing={2}>
        <Box>
          <Typography variant="body1">Role</Typography>
          <VrsGroupedSelect
            outerSx={{ width: "150px" }}
            value={roleFilter}
            groups={filteredRoles}
            onSelected={setRoleFilter}
          />
        </Box>
        <Box>
          <Typography variant="body1">Hospitals</Typography>
          <VrsSelect
            outerSx={{ width: "150px" }}
            options={hospitalOptions}
            onSelected={setHospitalFilter}
            value={hospitalFilter}
          />
        </Box>
        <Box display="flex" pt={2.2}>
          <Button onClick={clearFilters}>
            <Typography
              sx={{
                textTransform: "none",
                textDecoration: "underline",
                color: (theme) => theme.veris.colors.amethyst.normal,
              }}
            >
              Clear all
            </Typography>
          </Button>
        </Box>
      </Stack>
    </>
  );
};

export function UsersTableContainer(): JSX.Element {
  const users = useAppSelector(selectUsers);
  const usersStatus = useAppSelector(selectUsersStatus);
  const [activeFilter, setActiveFilter] = useState<string | AccountStatus>("All");
  const [userTypeFilter, setUserTypeFilter] = useState<string>("");
  const [hospitalFilter, setHospitalFilter] = useState<string>("");
  const [sortingFilter, setSortingFilter] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [roleFilter, setRoleFilter] = useState<string>("");

  const dispatch = useAppDispatch();
  const tableRef = useRef<HTMLTableElement>(null);
  const error = useAppSelector(selectActionError);
  const remainingSeconds = useAppSelector(selectRemainingSeconds);
  const targetTimestamp = useTargetTimestamp(remainingSeconds);
  const remainingTime = useCountDown(targetTimestamp);
  const navigate = useNavigate();

  const errorOpen = (): boolean => {
    if (remainingSeconds) return !!error && remainingTime !== 0;
    return !!error;
  };

  dayjs.extend(updateLocale);
  dayjs.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s ago",
      s: "1s",
      m: "1m",
      mm: "%dm",
      h: "1h",
      hh: "%dh",
      d: "1 day",
      dd: "%d days",
      w: "%d week",
      ww: "%d weeks",
      M: "a month",
      MM: "%d months",
      y: "a y",
      yy: "%d yrs",
    },
  });

  const clearFilters = () => {
    setActiveFilter("All");
    setUserTypeFilter("");
    setHospitalFilter("");
    setSortingFilter("");
    setSearchQuery("");
    setRoleFilter("");
    dispatch(getUsersAsync({ limit: USERS_FETCHING_LIMIT }));
  };

  const fetchUsers = useCallback(() => {
    dispatch(
      getUsersAsync({
        sorting: sortingFilter === "" ? undefined : ([sortingFilter] as UserSorting[]),
        type: userTypeFilter === "" ? undefined : ([userTypeFilter] as UserTypeResponse[]),
        accountStatus: activeFilter === "All" ? undefined : (activeFilter as AccountStatus),
        hospital: hospitalFilter === "" ? undefined : Number(hospitalFilter),
        search: searchQuery ?? undefined,
        limit: USERS_FETCHING_LIMIT,
        medRole:
          roleFilter === "" ? undefined : (roleFilter as MedRoleFilterEnum | TechRoleFilterEnum),
      }),
    );
  }, [searchQuery, hospitalFilter, activeFilter, userTypeFilter, sortingFilter, roleFilter]);

  const debouncedSearch = useCallback(
    debounce((searchObj) => {
      dispatch(getUsersAsync(searchObj));
      if (tableRef.current) tableRef.current.scrollIntoView({ block: "start" });
    }, 500),
    [],
  );

  const onValueChange = (value: string, filterName: string) => {
    setSearchQuery(value);

    const trimmedValue = value.trim();

    if (trimmedValue === "" || trimmedValue.length >= 3) {
      debouncedSearch({
        sorting: sortingFilter === "" ? undefined : ([sortingFilter] as UserSorting[]),
        type: userTypeFilter === "" ? undefined : ([userTypeFilter] as UserTypeResponse[]),
        accountStatus: activeFilter === "All" ? undefined : activeFilter,
        hospital: hospitalFilter === "" ? undefined : Number(hospitalFilter),
        search: filterName === "search" ? trimmedValue : searchQuery,
        limit: USERS_FETCHING_LIMIT,
        medRole: roleFilter === "" ? undefined : roleFilter,
      });
    } else {
      debouncedSearch.cancel();
    }
  };

  useEffect(() => {
    fetchUsers();
    if (tableRef.current) tableRef.current.scrollIntoView({ block: "start" });
  }, [dispatch, userTypeFilter, activeFilter, hospitalFilter, sortingFilter, roleFilter]);

  return (
    <Box width="100%" py={2.5} px={2} pb={1}>
      <Box display="flex" my={2.5}>
        {error && (
          <Snackbar
            autoHideDuration={SNACKBAR_AUTOHIDE_DURATION}
            open={errorOpen()}
            onClose={() => dispatch(clearActionErrorMessage())}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <VrsAlert
              message={
                remainingSeconds
                  ? `Please try again ${dayjs
                      .duration(remainingTime)
                      .format(dateFormats["m:ss"])} minutes.`
                  : error
              }
              severity="error"
              onClose={() => dispatch(clearActionErrorMessage())}
            />
          </Snackbar>
        )}

        <TableFilters
          userTypeFilter={userTypeFilter}
          activeFilter={activeFilter}
          setUserTypeFilter={setUserTypeFilter}
          setActiveFilter={setActiveFilter}
          searchQuery={searchQuery}
          hospitalFilter={hospitalFilter}
          setHospitalFilter={setHospitalFilter}
          onValueChange={onValueChange}
          clearFilters={clearFilters}
          sortingFilter={sortingFilter}
          roleFilter={roleFilter}
          setRoleFilter={setRoleFilter}
        />
      </Box>
      <UsersTable
        userStatus={usersStatus}
        usersData={users}
        unblockUser={(userId) => dispatch(unblockUserAsync(userId))}
        deleteUser={(userId) => dispatch(deleteUserAsync({ userId }))}
        disableUser={(userId) => dispatch(disableUserAsync({ userId }))}
        resendInvite={(userId) => dispatch(resendInviteAsync(userId))}
        fetchMore={() => {
          if (usersStatus === "idle")
            dispatch(
              getUsersAsync({
                sorting: sortingFilter === "" ? undefined : ([sortingFilter] as UserSorting[]),
                type: userTypeFilter === "" ? undefined : ([userTypeFilter] as UserTypeResponse[]),
                accountStatus: activeFilter === "All" ? undefined : (activeFilter as AccountStatus),
                hospital: hospitalFilter === "" ? undefined : Number(hospitalFilter),
                search: searchQuery ?? undefined,
                offset: users.items.length,
                limit: USERS_FETCHING_LIMIT,
                medRole:
                  roleFilter === ""
                    ? undefined
                    : (roleFilter as MedRoleFilterEnum | TechRoleFilterEnum),
              }),
            );
        }}
        setSortingFilter={setSortingFilter}
        sortingFilter={sortingFilter}
        ref={tableRef}
        onUserClick={(userId: number) =>
          navigate(replaceRouteParam("USER_DETAILS", ":userId", String(userId)))
        }
      />
      {usersStatus === "loading" && users.offset === 0 && users.items.length === 0 && (
        <Box display="flex" justifyContent="center" alignItems="center" p={2}>
          <CircularProgress />
        </Box>
      )}
      {usersStatus === "failed" && (
        <Box justifyContent="center" alignItems="center" width="100%">
          <VrsErrorMsg size="large" onClick={fetchUsers} />
        </Box>
      )}
    </Box>
  );
}

function hasValueInOptions(options: { label: string; value: string }[], value: string) {
  return options.some((option) => option.value.includes(value));
}
