import { Box, Grid, Typography, useTheme } from "@mui/material";
import { IconName, VrsButton, VrsDialog, VrsIcon } from "@veris-health/web-core";
import { AxiosError } from "axios";
import { FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { VrsFormInputField } from "../../../ui/components/VrsFormInputField";
import SnackbarUtils from "../../../utils/SnackbarUtils";
import { getHospitalsAsync } from "../../Users/userSlice";
import { createHospital } from "../api/actionsApi";
import { ApiErrorResponse } from "../../../constants";

const validationSchema = yup.object().shape({
  hospitalName: yup.string().required("Hospital name is required").min(1).max(100),
});

const initialValues = {
  hospitalName: "",
};

export const AddHospital = (): JSX.Element => {
  const [showAddHospitalModal, setShowAddHospitalModal] = useState(false);
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: ({ hospitalName }) => {
      createHospital(hospitalName)
        .then(() => {
          SnackbarUtils.success("Hospital sucessfully added.");
          dispatch(getHospitalsAsync());
        })
        .catch((error: AxiosError<ApiErrorResponse>) => {
          const errorMessage = error.response
            ? error.response.data?.message
            : "Something went wrong, please try again";
          SnackbarUtils.error(errorMessage);
        })
        .finally(() => onClose());
    },
  });

  const { errors, handleSubmit, handleChange, handleBlur, isValid, dirty, touched, resetForm } =
    formik;

  const { hospitalName } = formik.values;

  function onClose() {
    resetForm();
    setShowAddHospitalModal(false);
  }

  return (
    <Box pb={2}>
      <VrsButton buttonType="primary" onClick={() => setShowAddHospitalModal(true)}>
        <Typography variant="bodySemibold" fontWeight={400} pt={0.25} pl={2}>
          Add Hospital
        </Typography>
        <Box pl={1} pt={0.75} pr={2}>
          <VrsIcon name={IconName.Plus} size={18} stroke={theme.veris.colors.neutrals.white} />
        </Box>
      </VrsButton>

      <VrsDialog
        title="Add hospital"
        sx={{ "& .MuiPaper-root": { height: "auto" } }}
        open={showAddHospitalModal}
        onClose={() => onClose()}
        width="600px"
      >
        <FormikProvider value={formik}>
          <form onSubmit={handleSubmit}>
            <Grid item xs={12} style={{ width: "91.5%" }}>
              <VrsFormInputField
                name="hospitalName"
                type="text"
                label="Hospital"
                value={hospitalName}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.hospitalName && errors.hospitalName ? errors.hospitalName : " "}
                error={!!touched.hospitalName && Boolean(errors.hospitalName)}
                isValidField={isValid}
              />
            </Grid>
            <Grid item xs={12} marginTop={4} display="flex" justifyContent="flex-end">
              <VrsButton buttonType="primary" type="submit" disabled={!isValid || !dirty}>
                Save
              </VrsButton>
            </Grid>
          </form>
        </FormikProvider>
      </VrsDialog>
    </Box>
  );
};
