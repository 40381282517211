import React, { useMemo, useState } from "react";
import { Typography, Box, DialogContent, Grid, FormLabel, useTheme } from "@mui/material";
import { AxiosError } from "axios";
import { IconName, Option, VrsButton, VrsDialog, VrsIcon, VrsSelect } from "@veris-health/web-core";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { selectHospitals } from "../../Users/userSlice";
import { registerPatientsFromCSV } from "../api/actionsApi";
import { downloadCsvData } from "../../../utils/files";
import SnackbarUtils from "../../../utils/SnackbarUtils";
import { ApiErrorResponse } from "../../../constants";

export const CSVPatientsImport = (): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<File>();
  const [hospital, setHospital] = useState<string>();
  const [error, setError] = useState<string>();
  const [failedImports, setFailedImports] = useState<string>();

  const hospitals = useAppSelector(selectHospitals);
  const theme = useTheme();

  const hospitalOptions: Option[] = useMemo(() => {
    return hospitals.map((item) => ({ label: item.name, value: item.id.toString() }));
  }, [hospitals]);

  const handleClose = () => {
    setHospital(undefined);
    setFile(undefined);
    setError(undefined);
    setFailedImports(undefined);
    setOpen(false);
  };

  const onSubmit = () => {
    if (hospital && file) {
      setFailedImports(undefined);
      setError(undefined);
      registerPatientsFromCSV(+hospital, file)
        .then(() => {
          SnackbarUtils.success("Successfully imported patients");
          setError(undefined);
          handleClose();
        })
        .catch((err: AxiosError<ApiErrorResponse>) => {
          if (err.response?.data.message || err.response?.data.detail) {
            setError(err.response.data.message);
            setFailedImports(undefined);
          } else {
            setError("Some of the imports failed!");
            setFailedImports(err.response?.data as string);
          }
        });
    }
  };

  return (
    <Box pb={2} display="flex" justifyContent="flex-start">
      <VrsButton buttonType="primary" size="small" onClick={() => setOpen(true)}>
        <Typography pl={3} variant="bodySemibold" fontWeight={400} pt={0.25}>
          Import From CSV
        </Typography>
        <Box pr={3} pl={1} pt={0.75}>
          <VrsIcon name={IconName.Plus} size={18} stroke={theme.veris.colors.neutrals.white} />
        </Box>
      </VrsButton>
      <VrsDialog
        open={open}
        onClose={() => handleClose()}
        title="Import patients from CSV"
        sx={{ "& .MuiPaper-root": { height: "auto" } }}
      >
        <DialogContent sx={{ overflowY: "unset" }}>
          <Grid item xs={12} container spacing={4} style={{ margin: "auto", width: "auto" }}>
            <Grid item xs={6}>
              <Box>
                <Typography mb={1} variant="subtitle2">
                  Hospital
                </Typography>
                <FormLabel />
                <VrsSelect
                  value={hospital}
                  options={hospitalOptions}
                  placeholder="Choose hospital"
                  onSelected={setHospital}
                  maxHeight="250px"
                />
              </Box>
            </Grid>
            <Grid item xs={6} pb={1} sx={{ display: "flex", alignItems: "end" }}>
              <input
                type="file"
                id="csvFileInput"
                accept=".csv"
                onChange={(e) => {
                  const csv = e.target.files ? e.target.files[0] : null;
                  if (!csv) {
                    return;
                  }
                  setFile(csv);
                }}
              />
            </Grid>
            <Grid
              item
              xs={10}
              marginTop={4}
              style={{ display: "flex", flexDirection: "row-reverse" }}
            >
              {failedImports && (
                <VrsButton
                  buttonType="primary"
                  variant="text"
                  color="primary"
                  onClick={() => downloadCsvData(failedImports)}
                >
                  Download failed imports
                </VrsButton>
              )}
              <Typography color={theme.veris.colors.errors.normal} pt={1.5} variant="subtitle1">
                {error}
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              marginTop={4}
              style={{ display: "flex", flexDirection: "row-reverse" }}
            >
              <VrsButton
                buttonType="primary"
                disabled={!hospital || !file}
                variant="contained"
                color="primary"
                onClick={onSubmit}
              >
                Import
              </VrsButton>
            </Grid>
          </Grid>
        </DialogContent>
      </VrsDialog>
    </Box>
  );
};
