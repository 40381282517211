import React, { useEffect } from "react";
import {
  Typography,
  FormControl,
  FormLabel,
  styled,
  InputAdornment,
  outlinedInputClasses,
  inputBaseClasses,
  Box,
  Select,
  FormHelperText,
  MenuItem,
  TextField,
  selectClasses,
  useTheme,
  Theme,
} from "@mui/material";
import { Field } from "formik";
import { IconName, VrsIcon, AlertIcon } from "@veris-health/web-core";
import { VrsFormInputFieldProps } from "../../ui/components/VrsFormInputField";
import { allCountries, Country } from "./allCountriesList";

export interface CountrySelectProps extends VrsFormInputFieldProps {
  onSelected: (value: Country) => void;
  countryCode?: string;
}

interface StyledPhoneBoxProps {
  error?: boolean;
  isValidField?: boolean;
}

const StyledPhoneBox = styled(Box, {
  shouldForwardProp: (prop: string) => prop !== "error" && prop !== "isValidField",
})<StyledPhoneBoxProps>(({ theme, error, isValidField }) => ({
  display: "flex",
  height: "2.75rem",
  alignItems: "center",
  paddingLeft: 2,
  border: `1px solid ${theme.veris.colors.amethyst.light}`,
  borderRadius: +theme.shape.borderRadius * 1.5,
  ":focus-within": {
    paddingLeft: 1,
    border: `2px solid ${theme.veris.colors.amethyst.soft}`,
  },
  "&:hover": {
    paddingLeft: 0,
    border: `3px solid ${theme.veris.colors.amethyst.light}`,
  },
  ...(error && {
    paddingLeft: 2,
    border: `1px solid ${theme.veris.colors.errors.normal}`,
    color: theme.veris.colors.errors.normal,
    backgroundColor: theme.veris.colors.errors.soft,
  }),
  ...(isValidField && {
    paddingLeft: 2,
    border: `1px solid ${theme.veris.colors.amethyst.soft}`,
  }),
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  color: theme.veris.colors.neutrals["grey-mid"],
  height: "min-content",
  borderRadius: 0,
  borderRight: `1px solid ${theme.veris.colors.amethyst.light}`,
  [`& .${inputBaseClasses.input}`]: {
    padding: theme.spacing(1, 0, 1, 1),
  },
  [`.${selectClasses.select}`]: {
    display: "flex",
    alignItems: "center",
    paddingRight: "23px !important",
  },
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    borderWidth: 0,
  },
  [`&.${outlinedInputClasses.root}`]: {
    [`&.${outlinedInputClasses.focused} fieldset`]: {
      border: "none",
    },
  },
  [`.${selectClasses.icon}`]: {
    right: 0,
    webkitTransform: "none",
    transform: "none",
    paddingBottom: "9px !important",
  },
}));

const StyledInputField = styled(Field)(({ theme }) => ({
  marginLeft: theme.spacing(1.45),
  [`& .${inputBaseClasses.input}`]: {
    ...theme.typography.body,
    padding: theme.spacing(0.7, 0, 0, 0),
    ":-internal-autofill-selected": {
      boxShadow: "0 0 0 30px white inset !important",
    },
  },
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    borderWidth: 0,
  },
  [`& .${inputBaseClasses.root}`]: {
    paddingRight: theme.spacing(1.1),
    [`&.${outlinedInputClasses.focused} fieldset`]: {
      border: "none",
    },
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  component: "li",
  padding: theme.spacing(0.58, 1),
  color: theme.veris.colors.neutrals["grey-mid"],
  borderRadius: +theme.shape.borderRadius,
  marginBottom: 1,
  "&:hover": {
    backgroundColor: theme.veris.colors.neutrals["grey-2"],
    color: theme.veris.colors.neutrals.black,
  },
  "&.Mui-selected": {
    color: theme.veris.colors.neutrals.black,
  },
}));

export function PhoneFormInputField({
  name,
  label,
  value,
  error,
  helperText,
  onChange,
  isValidField,
  onSelected,
  onBlur,
  countryCode,
}: CountrySelectProps): JSX.Element {
  const [selected, setSelected] = React.useState<Country>({
    name: "United States",
    abbr: "US",
    code: "1",
  });
  const theme = useTheme();

  const handleSelectChange = (countryCodeValue: string) => {
    const selectedCountry = allCountries.find((country) => country.code === countryCodeValue);
    if (selectedCountry) {
      setSelected(selectedCountry);
      onSelected(selectedCountry);
    }
  };

  useEffect(() => {
    if (countryCode) handleSelectChange(countryCode);
  }, [countryCode]);

  return (
    <FormControl fullWidth required>
      {label && (
        <Typography m={0} variant="subtitle2">
          {label}
          <FormLabel />
        </Typography>
      )}

      <StyledPhoneBox error={error} isValidField={!!isValidField}>
        <StyledSelect
          defaultValue="1"
          value={selected.code}
          onChange={(event) => handleSelectChange(event.target.value as string)}
          IconComponent={(props) => (
            <VrsIcon
              {...props}
              name={IconName.ArrowDown}
              stroke={theme.veris.colors.neutrals["grey-mid"]}
            />
          )}
          autoWidth
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: "8.688rem",
                maxWidth: "7.75rem",
                borderRadius: ({ shape }) => +shape.borderRadius * 0.5,
                border: ({ veris }) => `1px solid ${veris.colors.neutrals["grey-2"]}`,
                boxShadow: "none",
                padding: 1,
              },
            },
          }}
        >
          {allCountries.map((option) => (
            <StyledMenuItem key={option.name} value={option.code}>
              <img
                loading="lazy"
                width="24px"
                height="16.55px"
                style={{ marginRight: 4, flexShrink: 0 }}
                src={`https://flagcdn.com/w20/${option.abbr.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.abbr.toLowerCase()}.png 2x`}
                alt={option.name}
              />
              <Typography pt={0.5} variant="body">
                {option.abbr} +{option.code}
              </Typography>
            </StyledMenuItem>
          ))}
        </StyledSelect>

        <StyledInputField
          size="small"
          variant="outlined"
          as={TextField}
          name={name}
          placeholder={label}
          value={value}
          type="text"
          onChange={onChange}
          onBlur={onBlur}
          fullWidth
          error={error}
          sx={{ border: "none" }}
          InputProps={{
            endAdornment: <InputAdornment position="end">{error && <AlertIcon />}</InputAdornment>,
          }}
        />
      </StyledPhoneBox>

      <FormHelperText
        sx={{
          marginLeft: 0,
          marginTop: helperText ? "3px" : 0,
          color: ({ veris }: Theme) => veris.colors.errors.normal,
        }}
      >
        {helperText}
      </FormHelperText>
    </FormControl>
  );
}
